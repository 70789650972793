/*eslint-disable*/

/*
  数据以excel文件导出，纯前端
  插件：js-xlsx
  插件地址：https://github.com/SheetJS/sheetjs
*/

import XLSX from "xlsx";
import XLSXS from "xlsx-style";

export default function exportExcel(_data,excelname){
  let name = excelname || "ADroi_export";
  var worksheet = XLSX.utils.aoa_to_sheet(_data);
  var new_workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(new_workbook, worksheet, "Sheet1");
  const workbookBlob = workbook2blob(new_workbook)
  openDownloadDialog(workbookBlob, name + ".xlsx")
  // XLSX.writeFile(new_workbook, name + ".xlsx");
}

function workbook2blob(workbook) {
  // 生成excel的配置项
  var wopts = {
    // 要生成的文件类型
    bookType: "xlsx",
    // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    bookSST: false,
    type: "binary"
  }
  var wbout = XLSXS.write(workbook, wopts)
  // 将字符串转ArrayBuffer
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length)
    var view = new Uint8Array(buf)
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }
  let buf = s2ab(wbout)
  var blob = new Blob([buf], {
    type: "application/octet-stream"
  })
  return blob
}
// 将blob对象 创建bloburl,然后用a标签实现弹出下载框
function openDownloadDialog(blob, fileName) {
  if (typeof blob === "object" && blob instanceof Blob) {
    blob = URL.createObjectURL(blob) // 创建blob地址
  }
  var aLink = document.createElement("a")
  aLink.href = blob
  // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，有时候 file:///模式下不会生效
  aLink.download = fileName || ""
  var event
  if (window.MouseEvent) event = new MouseEvent("click")
  //   移动端
  else {
    event = document.createEvent("MouseEvents")
    event.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
  }
  aLink.dispatchEvent(event)
}